@import "../mixins/media";

#aboutMessage {
  @include hover_link-text;
  margin-top: -16px;
  h4 {
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    font-weight: bold;
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .ec-borderedDefs:first-child {
    border-top: none;
  }
  dl {
    padding: 8px 0;
    @include media_desktop {
      padding: 16px 0;
    }
  }
  dt {
    padding-top: 0;
  }
  dd {
    margin-bottom: 0;
    padding-inline: 8px;
    line-height: $line-height-base;
    @include media_desktop {
      padding-inline: 16px;
    }
  }
  .aline-items-end {
    align-items: flex-end;
  }
}
