@import url('../../../../../node_modules/normalize.css/normalize.css');
@import "mixins/variables";
@import "../../../../../node_modules/bootstrap/scss/bootstrap";


:root {
  --bs-body-font-size: 1.6rem;
  --bs-body-line-height: 1.4;
  --bs-body-color: #525263;
}
html {
  font-size: 62.5%;
}
body {
  transition: z-index 0ms 5.28455ms;
}
a {
  color: $text-link-color;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}
p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/11.4.topicpath";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/12.9.calendar";
@import "project/12.10.bnrFreearea";
@import "project/12.11.itemList";
@import "project/12.12.bottomInfo";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/13.5.categoryCountent";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
@import "project/23.1.about";
