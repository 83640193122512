@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  transition: transform 0.3s;
  background: #fff;

  .have_curtain & {
    transform: translateX(-260px);
  }
  &__header {
    background: #fff;
    position: relative;
    z-index: 1;
  }
  & &__contentTop {
    padding: 0;
  }
  & &__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
  }
  & &__main {
    width: 100%;
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: 75%;
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  &__mainBottom {
    display: flex;
    flex-wrap: wrap;
  }
  & &__left,
  & &__right {
    display: none;
    @include media_desktop() {
      display: block;
      width: 25%;
    }
  }
  & &__left {
    padding-left: $padding-pc;
  }
}


.ec-headerRole {
  @include container;
  padding-top: 15px;
  position: relative;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
}

.ec-headerNaviRole {
  @include container;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 8px;
  @include reset_link;

  @include media_desktop {
    padding-top: 15px;
    padding-bottom: 40px;
  }
  &__logo {
    max-width: calc(100% - 100px);
    @include media_desktop {
      width: 50%;
    }
    .copy {
      margin: 0;
      font-size: 0.9rem;
      @include media_desktop {
        font-size: 1.6rem;
      }
    }
    .header_logo {
      margin: 30px;
      font-size: 2rem;
      @include media_desktop {
        width: 400px;
        margin: 0;
        font-size: 4rem;
      }
      img {
        width: 100%;
        aspect-ratio: 400/102;
      }
    }
  }
  & &__search {
    display: none;
    @include media_desktop() {
      width: calc(100% - 410px);
      min-width: 540px;
      display: block;
      padding-top: 26px;
      @include reset_link;
    }
  }
  & &__nav {
    display: none;
    @include reset_link;
    @include media_desktop {
      display: block;
      margin-left: auto;
      padding-top: 26px;
    }
  }
  & &__cart {
    @include media_desktop {
      display: inline-block;
      position: static;
    }
  }
  & &__navSP {
    @include media_desktop {
      display: none;
    }
  }
  .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ec-headerNavSP {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background: transparent;
  width: 44px;
  height: 44px;
  font-size: 24px;
  @include media_desktop {
    display: none;
  }
  span {
    position: relative;
    height: 3px;
    width: 24px;
    background: currentColor;
    transition: $transition-default;
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 0;
      display: inline-block;
      height: 3px;
      width: 24px;
      background: currentColor;
      transform: translateZ(0);
      backface-visibility: hidden;
      transform-origin: 0% 50%;
      transition: $transition-default;
    }
    &:before {
      top: -6px;
    }
    &:after {
      top: 6px;
    }
  }
  .have_curtain & {
    span {
      background: transparent;
      &:before {
        transform: translateX(4px) translateY(-3px) rotate(45deg);
      }
      &:after {
        transform: translateX(4px) translateY(2px) rotate(-45deg);
      }
    }
  }
}
.ec-headerNavSP.is-active {
  //display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  & &__title {
    text-align: center;
    h1 {
      margin: 0;
      padding: 0;
    }
    a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;

      @include media_desktop() {
        font-size: 40px;
      }
      font-weight: bold;
      color: black;

      &:hover {
        opacity: .8;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 1.6rem;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @include media_desktop {
    flex-direction: row;
    margin-bottom: 0;
  }
  & &__item {
    margin-left: 0;
    a {
      display: flex;
      align-items: center;
      height: 5rem;
      padding: 0 10px;
      transition: $transition-default;
      color: $black;
      @include media_desktop {
        color: $darkBrown;
      }
      &:hover {
        color: #9797a1;
      }
    }
  }
  & &__itemIcon {
    display: flex;
    margin-right: 5px;
    font-size: 3rem;
    img {
      width: 1em;
      height: 1em;
    }
  }
  & &__itemLink {
    color: currentColor;
    font-size: 1.4rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch{
  @include clearfix;
  & &__category {
    float: none;
    height: 50px;
    @include media_desktop {
      float: left;
      width: 180px;
    }
    .ec-select {
      overflow: hidden;
      width: 100%;
      height: 100%;
      margin: 0;
      text-align: center;

      select {
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 0.5em 1em;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border-color: $border-color;
        outline: none;
        background: #fff;
        background-image: none;
        box-shadow: none;
        font-size: 1.4rem;
      }
    }
  }
  & &__keyword{
    position: relative;
    height: 50px;
    padding: 0 50px 0 1em;
    border-bottom: 1px solid $border-color;
    @include media_desktop {
      float: left;
      width: 350px;
      margin-left: 10px;
    }
    .ec-input {
      height: 100%;
    }
    input[type="search"]{
      width: 100%;
      height: 100%;
      border: 0 none;
      padding: 0.5em 0;
      box-shadow: none;
      background: none;
      color: $black;
      font-size: 1.4rem;
    }
    .ec-icon {
      width: 25px;
      height: 25px;
    }
  }
  & &__keywordBtn{
    width: 44px;
    height: 44px;
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    white-space: nowrap;
    z-index: 1;
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  display: none;
  @include media_desktop() {
    display: block;
    width: 100%;
    @include reset_link;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  vertical-align: bottom;
  @include media_desktop {
    display: inline-block;
  }
}

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  @include media_desktop {
    float: left;
  }
}

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #E8E8E8;
  margin: 0;
  padding: 0 1em;
  height: 5rem;
  color: #2e3233;;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  @include media_desktop {
    font-size: 1.5rem;
    color: #fff;
  }
}

.ec-itemNav__nav > li >  a {
  @include media_desktop {
    color: $black;
  }
}

.ec-itemNav__nav li > a:hover {
  background: rgba(204, 204, 204, 0.1);
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 0;
  left: 100%;
  @include media_desktop {
    display: block;
    width: 350px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    background-color: rgba(63,43,22,0.8);
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: .2s;
  @include media_desktop {
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  padding-left: 10px;
}

.ec-itemNav__nav > li:hover > a {
  background: rgba(204, 204, 204, 0.1);
}

.ec-itemNav__nav li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: 5rem;
  }
}

.ec-itemNav__nav li ul li ul:before {
  @include media_desktop {
    content: ">";
    width: 0;
    height: 0;
    position: absolute;
    top: 13px;
    left: -15px;
    color: #fff;
    font-size: 1.6rem;
  }
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  overscroll-behavior-y: contain;
  overflow-y: auto;
  width: 260px;
  height: 100vh;
  background: $lightBrown;
  //transform: translateX(300px);
  transform: translateX(100%);
  position: fixed;
  top: 0;
  right: 0;
  //z-index: 1;
  transition: $transition-default;
  @include media_desktop() {
    display: none;
  }
  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8;
  }

  .ec-headerSearch{
    padding: 16px 8px 26px;
    background: #EBEBEB;
  }

  .ec-headerCategoryArea{
    margin-bottom: 16px;
    .ec-headerCategoryArea__heading {
      display: none;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #e8e8e8;
      background: transparent;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      //background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a{
      padding-left: 60px;
      font-weight: normal;
    }
  }
  .ec-headerLinkArea {
    background: black;

    .ec-headerLink__list {
      border-top: 1px solid #ccc;
    }

    .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 1.6rem;
      font-weight: bold;
      color: white;
    }
    .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px;
    }
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  //transition: all .3s;
  //z-index: 100000;
  transition: $transition-default;

  @include media_desktop() {
    display: none;
  }
}

/*
 サイド、ドロワー内cate3.twig
 */
.drawer_block .item_photo {
  margin-bottom: 8px;
  @include media_desktop {
    margin-bottom: 15px;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  visibility: hidden;
  cursor: pointer;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
  & > * {
    margin: auto;
  }
  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & +* {
    margin-top: 50px;
  }
}
