@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole{
  @include container;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 30px;
  &::before{
    display: none;
  }
  & &__progress{
    width: 100%;
    text-align: center;
  }
  & &__error{
    width: 100%;
    text-align: center;
    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
    }
  }
  & &__totalText{
    margin-bottom: 0;
    padding: 16px 0 6px;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-inline: 10%;
      margin-bottom: 30px;
      padding: 0;
    }
    .message {
      padding-bottom: 16px;
    }
    #cart_item__point_info strong {
      color: $black;
    }
  }
  & &__cart{
    @include media_desktop {
      margin: 0 10%;
    }
  }
  & &__actions{
    @include media_desktop {
      width: 33%;
      min-width: 256px;
      margin-inline: auto 10%;
    }
  }
  &__actionsLink {
    margin-bottom: 8px;
    @include media_desktop {
      margin-bottom: 16px;
      font-size: 1.2rem;
      a {
        color: $black;
      }
    }
  }
  & &__total{
    padding: 8px 0 16px;
    font-weight: bold;
    text-align: right;
    @include media_desktop {
      padding-bottom: 32px;
    }
  }
  & &__totalAmount{
    margin-left: 5px;
    color: $black;
    vertical-align: middle;
    @include media_desktop {
      font-size: 2.4rem;
    }
  }
  &__totalAmountText {
    @include media_desktop {
      padding-top: 0.5em;
    }
  }
  .ec-blockBtn--action,
  .ec-blockBtn--cancel{
    margin-bottom: 8px;
    @include media_desktop {
      margin-bottom: 16px;
    }
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include media_desktop {
    border-top: none;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader{
  display: none;
  width: 100%;
  background: #F4F3F0;
  @include media_desktop {
    display: table-row;
  }
  & &__label{
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
  }
}
.ec-cartCompleteRole {
  @include container;
  .ec-reportDescription {
    text-align: left;
    p {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow{
  display: table-row;
  & &__delColumn{
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop{
      width: 8.3333333%;
    }
    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn{
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 10px 8px;
    @include media_desktop {
      padding: 16px;
    }
  }
  & &__img {
    @include hover_link-img;
    display: table-cell;
    min-width: 80px;
    max-width: 100px;
    vertical-align: middle;
    padding-right: 8px;
    @include media_desktop {
      min-width: 160px;
      padding-right: 16px;
    }
  }
  & &__summary{
    display: table-cell;
    font-weight: bold;
    vertical-align: middle;
    @include media_desktop {
      vertical-align: middle;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
      a {
        display: block;
        color: $text-default-color;
        &:hover {
          color: #9797a1;
        }
      }
    }
    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }
    }
  }
  & &__amountColumn{
    display: table-cell;
    padding: 10px 8px;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    @include media_desktop {
      width: 16.66666667%;
      padding: 16px;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center;
      @include media_desktop {
        display: block;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;


      .ec-cartRow__amountUpButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
  & &__subtotalColumn{
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%;
    vertical-align: middle;
    @include media_desktop {
      display: table-cell;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow{
  & &__amountColumn{
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: $bg-red;
  margin-bottom: 20px;

  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}

/*
 SSL証明
 */
#comodoTL {
  display: block;
  font-size: 8px;
  padding-left: 18px;
}
.comodo img {
  width: 400px;
}
