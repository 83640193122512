@import "../mixins/media";

/*
　パンくずリスト
 */
#topicpath {
  padding: 0 $padding-sp;
  border-top: 1px solid $border-color;
  border-bottom: 1px dotted $border-color;
  @include media_desktop {
    padding: 5px $padding-pc 16px;
    border: 0 none;
  }
  ol {
    margin: 0;
    padding: 1em 0;
    list-style: none;
    overflow: hidden;
    @include media_desktop {
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      border-bottom: 1px solid $border-color;
    }
  }
  li {
    float: left;
    color: #333;
    font-size: 1.2rem;
    @include media_desktop {
      font-size: 1.6rem;
    }
    &:not(:last-child)::after {
      content: "＞";
    }
    &:last-child {
      font-weight: bold;
    }
    #page_product_detail & {
      &:nth-last-child(2) {
        font-weight: bold;
        &::after {
          display: none;
        }
      }
      &:last-child {
        display: none;
      }
    }
  }
  a {
    float: left;
    display: inline-block;
    &:hover {
      color: #b8a293;
    }
  }
}
