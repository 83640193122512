@import "../mixins/media";
/*
 商品一覧
 カテゴリーコンテンツ
 */

.category_content {
  padding-bottom: 16px;
  @include clearfix;

  h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-size: 1.6rem;
    font-weight: bold;
    @include media_desktop {
      font-size: 2.4rem;
    }
  }
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  .colorcate {
    position: relative;
    float: left;
    width: 50%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 16.6666666%;
      padding-inline: 8px;
    }
  }
  .item_photo {
    display: block;
    margin: 0 0 8px;
    text-align: center;
    @include media_desktop {
      margin: 0 0 15px;
    }
    img {
      width: 100%;
    }
  }
  .category_description {
    float: left;
    width: 100%;
    position: relative;
    @include media_desktop {
      padding-bottom: 15px;
    }
  }
  .category_photo {
    position: relative;
    float: left;
    width: 100%;
    padding: 8px;
    @include media_desktop {
      padding-top: 26px;
      width: 30%;
    }
  }
  .category_txt {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 70%;
    }
  }
  .category_photo2 {
    position: relative;
    float: left;
    width: 100%;
    padding: 8px;
    @include media_desktop {
      padding-top: 26px;
      width: 50%;
    }
  }
  .category_txt2 {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 50%;
    }
  }

}
