@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: calc(2 * #{$padding-pc});
    }
  }
  & &__title {
    .ec-headingTitle {
      margin-bottom: 5px;
      color: $black;
      font-size: 2rem;
      font-weight: normal;
      @include media_desktop {
        margin-bottom: 10px;
        font-size: 3.0rem;
      }
    }
  }
  & &__tag {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    border: solid 1px $lightBlueGray;
    border-radius: 3px;
    background-color: $exLightBlueGray;
  }
  & &__priceRegular {
    margin-top: 10px;
    color: $text-normalPrice-color;
    .small {
      font-weight: normal;
      @include media_desktop {
        font-size: 1.2rem;
      }
    }
  }
  &__price {
    margin-top: 10px;
    font-size: 2.6rem;
    @include media_desktop {
      font-size: 2.8rem;
    }
    .ec-price {
      color: $black;
      font-weight: bold;
      .small:first-child {
        margin-right: -5px;
      }
    }
    .small {
      font-size: 1.2rem;
      font-weight: normal;
      @include media_desktop {
        font-size: 1.6rem;
      }
    }
  }
  & &__code {
    padding: 8px 0;
    @include media_desktop {
      padding: 14px 0;
      border-top: 1px dotted $border-color;
    }
  }
  & &__actions {
    padding-top: 8px;
    @include media_desktop {
      padding-top: 14px;
    }
    .ec-select {
      margin-bottom: 8px;
      @include media_desktop {
        margin-bottom: 14px;
      }
      select {
        height: 40px;
        max-width: 100%;
        @include media_desktop {
          max-width: 350px;
        }
      }
    }
    .ec-numberInput input {
      margin-bottom: 8px;
      @include media_desktop {
        margin-bottom: 14px;
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 16px;
    @include media_desktop {
      width: 60%;
      min-width: 250px;
    }
  }
  & &__description {
    margin-top: 24px;
    margin-bottom: 16px;
    @include media_desktop {
      margin-top: 30px;
    }
  }
  .small {
    font-size: 1.7rem;
    font-weight: normal;
    @include media_desktop {
      font-size: 1.6rem;
    }
  }
}

/*
  商品説明　table
 */
.ec-productRole__description {
  table.table01 {
    width: 100%;
    border-right: #cccccc solid 1px;
    border-collapse: collapse;
  }

  table.table01 thead th {
    background: #F0EFE8;
    color: #FFF;
    padding: 10px 15px;
    border-right: #FFF solid 1px;
    border-bottom: #FFF solid 1px;
  }

  table.table01 thead th:last-child {
    border-right: #cccccc solid 1px;
  }

  table.table01 tbody th {
    background: #F0EFE8;
    padding: 10px 15px;
    border: #cccccc solid 1px;
    vertical-align: top;
  }

  table.table01 tbody tr:last-child th {
    border-bottom: #cccccc solid 1px;
  }

  table.table01 tbody td {
    background: #FFF;
    padding: 10px 15px;
    border-left: #cccccc solid 1px;
    border-bottom: #cccccc solid 1px;
    border-top: #cccccc solid 1px;
    vertical-align: top;
  }

  @media screen and (max-width: 640px) {
    table.table01 thead {
      display: none;
    }
    table.table01 tbody th {
      display: block;
    }
    table.table01 tbody td {
      display: block;
    }
    table.table01 tbody td::before {
      content: attr(label);
      float: left;
      clear: both;
      font-weight: bold;
    }
    table.table01 tbody td p {
      padding-left: 6em;
    }

  }

  table.table02 {
    width: 100%;
    border-right: #cccccc solid 1px;
    border-collapse: collapse;
  }

  table.table02 thead th {
    background: #625750;
    color: #FFF;
    padding: 10px 15px;
    border-right: #FFF solid 1px;
    border-bottom: #FFF solid 1px;
  }

  table.table02 thead th:last-child {
    border-right: #cccccc solid 1px;
  }

  table.table02 tbody th {
    background: #625750;
    color: #FFF;
    padding: 10px 15px;
    border: #FFF solid 1px;
    vertical-align: top;
  }

  table.table02 tbody tr:last-child th {
    border-bottom: #cccccc solid 1px;
  }

  table.table02 tbody td {
    background: #FFF;
    padding: 10px 15px;
    border-left: #cccccc solid 1px;
    border-bottom: #cccccc solid 1px;
    border-top: #cccccc solid 1px;
    vertical-align: top;
  }

  @media screen and (max-width: 640px) {
    table.table02 thead {
      display: none;
    }
    table.table02 tbody th {
      display: block;
    }
    table.table02 tbody td {
      display: block;
    }
    table.table02 tbody td::before {
      content: attr(label);
      float: left;
      clear: both;
      font-weight: bold;
    }
    table.table02 tbody td p {
      padding-left: 6em;
    }
  }
}

/*
  photoswipe
 */
#detail_photoswipe a {
  display: block;
  margin-bottom: 50px;
}
