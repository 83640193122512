@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole{
  @include container;
  margin-bottom: 16px;
  @include media_desktop {
    margin-bottom: 24px;
  }
  .main_visual {
    aspect-ratio: 1120/448;
    margin-bottom: 48px !important;
    @include media_desktop {
      margin-bottom: 52px !important;
    }
    .slick-dots {
      bottom: -54px;
      @include media_desktop {
        bottom: -60px;
      }
    }
    .slick-dots li button:before {
      content: " ";
      width: 12px;
      height: 12px;
      background-color: black;
      border-radius: 50%;
    }
  }
  .item {
    display: none;
  }
  .slick-initialized .item {
    display: block !important;
  }
  a:hover img {
    opacity: $opacity-default;
  }
}

/*
  商品詳細
 */
.ec-sliderItemRole{
  padding: $padding-sp 0 54px;
  margin-bottom: 8px;
  @include media_desktop {
    padding: 0;
  }
  ul{
    padding: 0;
    list-style: none;
  }
  .item_visual {
    @include media_desktop {
      padding-bottom: 16px;
    }

    >a:not(:first-child){
      display: none;
    }
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

  }
  .slideThumb{
    margin-bottom: 25px;
    width: 33%;
    opacity: .8;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 80%;
      height: auto;
      object-fit: cover;
    }
  }
}
