@import "../mixins/media";
/*
 TOP
  トップページコンテンツ new_product.twig
 */
.item_list {
  $this: &;
  @include hover_link-img;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
    @include media_desktop {
      padding-bottom: 24px;
    }
    h5 {
      display: block;
      width: 100%;
      font-size: 0.83em;
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
    }
    &__item {
      padding-inline: $padding-sp;
      padding-bottom: $padding-sp;
      @include media_desktop {
        padding-inline: $padding-pc;
      }
    }
  }
  &__list2 {
    @extend #{$this}__list;
    &__item {
      @extend #{$this}__list__item;
      width: 100%;
      @include media_desktop {
        width: 50%;
      }
    }
  }
  &__list3 {
    @extend #{$this}__list;
    &__item {
      @extend #{$this}__list__item;
      width: 100%;
      @include media_desktop {
        width: calc(100% / 3);
      }
    }
  }
  &__list4 {
    @extend #{$this}__list;
    &__item {
      @extend #{$this}__list__item;
      width: 50%;
      @include media_desktop {
        width: 25%;
      }
    }
  }

  // 1段目
  .item_area {
    display: flex;
    margin-inline: -$padding-sp;
    @include media_desktop {
      margin-inline: -$padding-pc;
    }
    &__item {
      width: 50%;
      padding-inline: $padding-sp;
      @include media_desktop {
        padding-inline: $padding-pc;
      }
    }
  }
  .comment_area {
    @include hover_link-text;
    h4 {
      font-size: 2.4rem;
      margin: 0 0 2px;
      font-weight: bold;
      @include media_desktop {
        font-size: 3.2rem;
        margin: 0 0 8px;
      }
    }
    h5 {
      margin: 0;
      font-size: 1.6rem;
      font-weight: bold;
      @include media_desktop {
        font-size: 2rem;
      }
    }
    .text-center {
      p {
        margin: 10px 0 15px;
        @include media_desktop {
          font-size: 2rem;
          margin: 24px 0 24px;
        }
      }
    }
    .GOODA {
      img {
        display: block;
      }
      p {
        margin: 0;
      }
    }
  }

  // 2段目
  .item_photo {
    display: block;
    margin: 0 0 8px;
    text-align: center;
    @include media_desktop {
      margin: 0 0 15px;
    }
    img {
      width: 100%;
    }
  }
  .item_name {
    margin: 0 0 8px;
    color: $text-beige;
    font-weight: bold;
  }
  .item_comment {
    color: $text-beige;
    font-weight: bold;
  }
  .item_name + .item_comment {
    color: $text-default-color;
    font-size: 1.4rem;
    font-weight: normal;
  }
}
