@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  margin-top: 32px;
  padding-top: 12px;
  padding-bottom: 40px;
  background: #f8f8f8;
  border-top: 1px solid #e8e8e8;

  @include media_desktop(){
    padding-top: 24px;
    margin-top: 80px;
  }
  & &__inner{
    @include container;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  margin: 0;
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link{
    display: inline-block;
    a {
      display: block;
      padding: 4px 8px;
      color: #444;
      @include media_desktop {
        padding: 8px;
      }
      &:hover {
        color: #686868;
      }
    }
  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  padding-top: 16px;
  text-align: center;
  color: #444;
  @include media_desktop {
    padding-top: 88px;
  }
  & &__logo{
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    @include reset_link();
    a {
      color: currentColor;
      @include media_desktop {
        font-size: 3rem;
      }
      &:hover {
        color: #686868;
      }
    }
  }
  & &__copyright{
    font-size: 1rem;
    @include media_desktop {
      font-size: 1.2rem;
    }
  }
}
