@import "../mixins/media";
/*
 TOP
  フリーエリアトップページ右　free.twig
 */

/* txt_bnr */
.txt_bnr_area {
  @include container;
  @include hover_link-img;
  @include hover_link-text;
  padding-bottom: 16px;
  @include media_desktop {
    width: 25%;
    padding-top: 77px;
  }
}

.txt_bnr {
  margin:0 0 16px;
}
