@import "./variables";
@import "./clearfix";

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */



//@mixin media_tablet(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}

@mixin media_desktop(){
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

//@mixin media_desktop2(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}
//
//@mixin media_desktop3(){
//  @media only screen and (min-width: 768px) {
//    @content;
//  }
//}


@mixin container(){
  margin: 0 auto;
  padding-inline: $padding-sp;
  box-sizing: border-box;
  @include clearfix;
  @include commonStyle();
  width: 100%;
  max-width: $max-width;
  @include media_desktop {
    padding-inline: $padding-pc;
  }

  //@media (min-width: $desktop) {
  //  width: 720 + 30px;
  //}
  //@media (min-width: $desktop2) {
  //  width: 940 + 30px;
  //}
  //@media (min-width: $desktop3) {
  //  width: 1140 + 30px;
  //}
}
@mixin mypageContainer(){
  margin-inline: auto;
  padding-inline: $padding-sp;
  box-sizing: border-box;
  @include clearfix;
  @include commonStyle();
  width: 100%;
  max-width: $max-width;
  @include media_desktop {
    padding-inline: $padding-pc;
  }
}

@mixin commonStyle(){
  font-size: 1.6rem;
  line-height: 1.4;
  color: $text-default-color;
  -webkit-text-size-adjust: 100%;

  //a {
  //color: #0092C4;
  //color: #A092C4;
  //text-decoration: none;
  //cursor: pointer;
  //}
  //a:hover,
  //a:focus,
  //a:active { color: #33A8D0;text-decoration: none; outline: none;}


  textarea { /* for chrome fontsize bug */
    font-family: sans-serif;
  }

  //ul, ol {
  //  list-style: none;
  //  margin: 0; padding: 0;
  //}
  //dl, dt, dd, li{
  //  margin: 0; padding: 0;
  //}

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

@mixin hover_link(){
  @include media_desktop {
    a {
      transition: $transition-default;
      &:hover {
        opacity: $opacity-default;
      }
    }
  }
}
@mixin hover_link-img(){
  @include media_desktop {
    a {
      &:hover {
        img {
          transition: $transition-default;
          opacity: $opacity-default;
        }
      }
    }
  }
}

@mixin hover_link-text(){
  @include media_desktop {
    a {
      color: $text-link-color;
      transition: $transition-default;
      &:hover {
        color: $black;
        opacity: inherit;
      }
    }
  }
}
