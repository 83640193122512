@import "../mixins/media";
@import "../mixins/projects";

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager{
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 2em 0 1em  ;
  text-align: center;
  & &__item,
  & &__item--active{
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative;
    @include reset_link();
    a{
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none;
    }
    a:hover{
      color: inherit;
    }
  }
  & &__item--active a {
    background: $clrGray;
  }
  & &__item:hover a{
    background: $clrGray;
  }

}
