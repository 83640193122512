@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/

.ec-newsRole {
  $this: &;
  @include container;
  @include hover_link-text;
  @include media_desktop {
    width: 75%;
    margin-bottom: 32px;
  }
  & &__headerImage {
    width: auto;
    margin-bottom: 32px;
  }
  & &__news {
    background: #F8F8F8;
    h2 {
      margin: 0;
      padding: 8px;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;
      @include media_desktop {
        padding: 16px;
        text-align: left;
        font-size: 2.4rem;
      }
    }
  }
  & &__newsItem {
    border-top: 1px dotted $border-color;
  }
  & &__newsHeading {
    display: flex;
    flex-direction: column;
    padding: 8px;
    cursor: pointer;
    @include media_desktop {
      flex-direction: row;
      padding: 16px;
    }
    &:hover {
      #{$this}__newsTitle {
        text-decoration: underline;
      }
      #{$this}__newsCloseBtn img {
        opacity: $opacity-default;
      }
    }
  }
  & &__newsDate {
    @include media_desktop {
      width: 7em;
    }
  }
  & &__newsColumn {
    display: flex;
    @include media_desktop {
      display: inline-flex;
      width: calc(100% - 7em);
    }
  }
  & &__newsTitle {
    width: calc(100% - 30px);
  }
  & &__newsClose {
    width: 30px;
    position: relative;
  }
  & &__newsCloseBtn {
    display: block;
    width: 21px;
    height: 21px;
    margin-left: auto;
    padding: 0;
    border-radius: 50%;
    border: none;
    background: $clrRightGray;
    position: absolute;
    top: 1px;
    right: 0;
    @include media_desktop {
      width: 25px;
      height: 25px;
      top: -1px;
    }
    img {
      width: 11px;
      transform: translateY(-1px);
      @include media_desktop {
        width: 20px;
        transform: translateY(0);
      }
    }
  }
  & &__newsDescription {
    display: none;
    margin: 0 8px 8px;
    overflow: hidden;
    @include media_desktop {
      margin: 0 16px 16px;
    }
    a {
      color: $text-link-color;
    }
  }
  &__newsItem.is_active &__newsCloseBtn img {
    transform: rotateX(180deg) translateY(2px);
  }
  #news_readmore {
    margin: 0 0 8px 8px;
    padding: 0;
    border: none;
    background: none;
    transition: $transition-default;
    color: $text-link-color;
    @include media_desktop {
      margin: 0 0 16px 16px;
    }
    &:hover {
      color: $black;
    }
  }
}
