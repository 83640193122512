@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/

/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress{
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none;
  @include media_desktop {
    margin-bottom: 30px;
    padding: 0;
  }

  & &__item{
    display:table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: $text-default-color;
      width: 100%;
      height: 0.25em;
      top: calc((30px - 0.25em)/2);
      left: 50%;
      z-index: -1;
      @include media_desktop {
        top: calc((42px - 0.25em)/2);
      }
    }
    &:last-child:after {
      display: none;
    }
  }
  & &__number{
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: $text-default-color;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    @include media_desktop(){
      line-height: 42px;
      width: 42px;
      height: 42px;
      font-size: 20px;
    }
  }
  & &__label {
    font-size: 12px;
  }
  .is-complete {
    .ec-progress__number {
      background: #ff9800;
    }
    .ec-progress__label {
      color: #ff9800;
    }
  }
}



/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
.ec-cartNaviWrap{
  @include media_desktop {
    position: relative;
  }
}
.ec-cartNavi{
  width: 50px;
  height: 44px;
  line-height: 42px;
  vertical-align: middle;
  text-align: center;

  @include media_desktop {
    display: flex;
    justify-content: space-between;
    width: auto;
    min-width: 206px;
    padding-inline: 16px;
    border: 1px solid #d9d9d9;
    white-space: nowrap;
    cursor: pointer;
  }

  & &__icon {
    position: relative;
    font-size: 3rem;
    border: none;
    background: transparent;
    @include media_desktop {
      display: flex;
      align-items: center;
    }
    img {
      vertical-align: middle;
      width: 1em;
      height: 1em;
      transition: $transition-default;
      @include fadeIn(inline-block,200ms);
      @include media_desktop {
        margin-right: 1rem;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      background: url('/html/template/gabbeh/assets/icon/cross-dark.svg') no-repeat;
      position: absolute;
      top: calc(50% - 1.2rem);
      left: 10px;
      opacity: 0;
    }
  }
  & &__badge{
    position: absolute;
    right: 1px;
    top: 5px;
    display: inline-block;
    height: 17px;
    padding: 3px 7px;
    border-radius: 10px;
    line-height: 1;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: #DE5D50;
    @include media_desktop {
      position: relative;
      top: 0;
      display: inline-block;
      min-width: 17px;
      font-size: 1.2rem;
    }
  }
  & &__label {
    display: none;
    @include media_desktop {
      display: flex;
    }
    &__label {
      padding-top: 0.15rem;
      font-size: 1.2rem;
    }
  }
  & &__price{
    display: inline-block;
    font-size: 1.6rem;
    font-weight: normal;
    vertical-align: middle;
  }
  .ec-cartNavi--cancel {
    height: 40px;
    line-height: 40px;
  }
}
.ec-cartNavi.is-active {

  .ec-cartNavi__icon {
    img {
      opacity: 0;
    }
    &:after {
      opacity: 1;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .ec-cartNavi__badge{
    display: none;
  }
}


/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  background: $clrGray;
  padding: $padding-sp;
  z-index: 20;
  position: absolute;
  right: 0;
  @include media_desktop {
    width: auto;
    min-width: 256px;
    padding: $padding-pc;
  }

  & &__cart {
    @include clearfix;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
  & &__cartImage {
    float: left;
    width: 45%;
    img {
      width: 100%;
    }
  }
  & &__cartContent {
    float: right;
    width: 55%;
    padding-left: 16px;
    box-sizing:border-box;
  }
  & &__action {
    .ec-blockBtn--action {
      color:#fff;
      margin-bottom: 8px;
    }
  }
  & &__cartContentTitle {
    margin-bottom: 8px;
  }
  & &__cartContentPrice {
    font-weight: bold;
  }
  & &__cartContentTax {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 2px;
  }
  & &__cartContentNumber {
    font-size: 1.4rem;
  }
  & &__cartTotal {
    margin-top: -16px;
    margin-bottom: 16px;
    padding: 8px;
    border-bottom: 1px solid #E8E8E8;
    text-align: right;
    font-size: 1.4rem;
    @include media_desktop {
      display: none;
    }
  }
  & &__cartTotalPrice {
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.ec-cartNaviIsset.is-active {
  display: block;
}



/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/


.ec-cartNaviNull {
  display: none;
  width: 100%;
  background: $clrGray;
  box-sizing: border-box;
  padding: calc($padding-sp * 3) $padding-sp;
  z-index: 3;
  position: absolute;
  right: 0;
  text-align: center;
  @include media_desktop {
    width: auto;
    min-width: 256px;
    padding-inline: $padding-pc;
  }

  & &__message {
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: $bg-red;
    p {
      margin: 0;
    }
  }
}

.ec-cartNaviNull.is-active {
  display: block;
}



/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox{
  background:#F3F3F3;
  padding: 16px;
  margin-bottom: 16px;
  @include media_desktop {
    position: sticky;
    top: 24px;
  }
  & &__spec{
    display: flex;
    justify-content: space-between;
    margin-bottom:8px;
    dt{
      font-weight: normal;
      text-align: left;
    }
    dd{
      text-align: right;
    }
    & &__specTotal {
      color: $clrRed;
    }
  }
  & &__total{
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight:bold;
  }
  & &__paymentTotal{
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight:bold;
    .ec-totalBox__price,
    .ec-totalBox__taxLabel{
        color: $clrRed;
    }
  }
  & &__price{
    margin-left: 16px;
    font-size: 16px;
    font-weight:bold;
    @include media_desktop {
      font-size: 24px;
    }
  }
  & &__taxLabel {
    margin-left: 8px;
    font-size: 12px;
    @include media_desktop {
      font-size: 14px;
    }
  }
  & &__taxRate {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom:8px;
    font-size: 10px;
    @include media_desktop {
      font-size: 12px;
    }
    dt{
      font-weight: normal;
      text-align: left;
      margin-right: 8px;
      &::before {
        content: "[ ";
      }
    }
    dd{
      text-align: right;
      &::after {
        content: " ]";
      }
    }
  }
  & &__pointBlock{
    padding: 18px 20px 10px;
    margin-bottom: 10px;
    background: #fff;
  }
  & &__btn {
    .ec-blockBtn--action,
    .ec-blockBtn {
      @include media_desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 2px;
        white-space: normal;
        line-height: inherit;
      }
    }
    .ec-blockBtn {
      margin-top: 8px;
    }
  }
}
