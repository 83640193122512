@import "../mixins/media";
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole{
  @include container;
  margin-bottom: 0;
  & &__infos{
    display: flex;
    margin-bottom: 16px;
    padding-top: 16px;
    flex-direction:column;
    @include media_desktop {
      padding-top: 0;
      flex-direction:row;
    }
  }

  & &__counter{
    width: 100%;
    margin-bottom: 16px;
    @include media_desktop {
      width: 50%;
      margin-bottom: 0;
      padding-top: 0.5em;
    }
  }

  & &__actions{
    width: 100%;
    padding-right: 8px;
    text-align: right;
    @include media_desktop {
      width: 50%;
      padding-right: 0;
    }
    .ec-select {
      margin-bottom: 20px;
    }
  }


}
